<template>
  <div class="bigBox">
    <div v-if="!isApp" class="tab" :class="navBarFixed ? 'navBarFixed': ''">
      <img @click="goback" src="../../../../static/common/logoBlack.png" alt="">
    </div>
    <div class="box">
      <div>
        <div id="login" :class="{ shaking: toShake}">
          <p>60s极速开户</p>
          <img src="/activity/voucher/other.png" alt="">
          <div class="interactive">
            <!-- <input v-model="form.phone" type="text" placeholder="请输入手机号">
            <div class="codeBox">
              <input v-model="form.code" type="text" placeholder="请输入验证码">
              <span v-if="verControl" @click="verCode(form.phone)">获取验证码</span>
              <span v-else>重新获取({{verSecond}})</span>
            </div> -->
            <button @click="goLink(model)">一键领取</button>
          </div>
          <span class="dollar">更有50美元惊喜等你领</span>
          <div v-if="controlImgCodeDialog" class="codeDialog">
            <img :src="imgcodelocal" alt="">
            <p>
              <span>图形验证码:</span>
              <input type="text" v-model="form.codeNumber">
            </p>
            <div>
              <button @click="()=>{this.controlImgCodeDialog = false}">取消</button>
              <button @click="confirm(form.codeNumber)">确定</button>
            </div>
          </div>
        </div>
      </div>
      <p class="rulesP" @click="rulesModel = true">活动规则</p>
      <img class="part1" src="/activity/voucher/part1.png" alt="">
      <img class="part2" src="/activity/voucher/part2.png" alt="">
      <img class="part3" src="/activity/voucher/part3.png" alt="">
      <img @click="live800" class="part4" src="/activity/voucher/part4.png" alt="">
    </div>
    <div class="mask" v-if="rulesModel">
      <div class="popup">
        <div class="closeBox">
          <img
            class="guanbi1"
            @click="rulesModel = false"
            src="../../../../static/common/wap/closer.png"
            alt=""
          />
        </div>
        <h3>活动规则</h3>
        <ul class="dialogContent">
          <li>
            <span>活动对象：</span><br/>
            活动期间，首次在本平台开立真实账户的用户
          </li>
          <li>
            <span>活动时间：</span>
            即日起至2023年10月31日23:59
          </li>
          <li>
            <span>活动内容：</span><br/>
            1. 活动期间，开立真实账户赠送500美元现金券一张；<br/>
            2. 现金券领取后可直接交易，使用有效期从当日00:00起计算，共计3天；<br/>
            3. 每张现金券仅可交易1笔，现金券持仓单需在最近一次04:00前平仓，或由系统自动平仓；<br />
            4. 现金券交易后盈利即送3美金，直接发放到MT账户，用户可直接提现；<br/>
            5. 盈利有效期为平仓后30天内，逾期扣回；<br/>
            6. 现金券仅限伦敦金/伦敦银交易品种。
          </li>
          <li>
            <span>注意事项：</span><br/>
            1. 客户参与活动需在本公司开立真实交易账户,参与该活动的新用户，为保证资金安全，在初始L1层级，账户强平比例上调至50%，升级后自动恢复为20%；<br/>
            2. 交易手数计算：开仓一手平仓一手，如此来回算一手；<br/>
            3. 本活动本公司新客户仅有一次机会参与，不可重复领取；<br/>
            4.本公司保留随时修订、暂停、终止本活动及任何相关规则条款之权利及其解释权。
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { verifyImgCode, quickRegister } from '../../../../api/info'
import { getGuid } from '../../../../utils/guid';
export default {
  name:'voucherWap',
  data(){
    return{
      form:{
        phone:'',
        code:'',
        codeNumber:'',
        // codeNumber:'',
      },
      // 控制显示验证码或读秒
      verControl:true,
      // 定时器倒计时
      refreshData:null,
      // 验证码读秒数
      verSecond: 60,
      // 控制图片验证码弹窗
      controlImgCodeDialog:false,
      // 图片验证码地址
      imgcodelocal:'',
      guid:'',
      // 是否抖动
      toShake: false,
      model:0,
      scroll: '',
      navBarFixed:false,
      isApp:false,
      rulesModel: false,
    }
  },
  created(){
    // 传给App.vue,不展示tabbar
    this.$emit('controlShow',false);
    // 获取浏览器参数model
    if(this.$route.query.model){
      this.model = this.$route.query.model;
    }
    if(this.$route.query.isApp){
      this.$emit('controlBottom',false);
      this.isApp = true;
    }
  },
  methods:{
    // 点击获取验证码
    verCode(attr){
      if(this.isPhone(attr)){
        this.controlImgCodeDialog = true;
        this.countdowm();
        this.guid = getGuid();
        // this.imgcodelocal = window.location.origin + '/api/proxy/v4/U104?key=' + this.guid;
        this.imgcodelocal = 'https://www.rlcvipltd.net/api/proxy' + '/v4/U104?key=' + this.guid;
      }else{
        this.$message({
          message: '请输入正确手机号!',
          type: 'warning',
        });
      }
    },
    // 提交验证码
    confirm(attr){
      if(attr){
        let data = {
          Mobile: this.form.phone,
          Token: this.guid,
          ImgCode: Number(attr),
        }
        // 获取图片验证码接口
        verifyImgCode(data).then(res=>{
          if(res.ok == 1){
            this.$message({
              message: res.msg,
              type: 'warning'
            });
            // this.form.code = res.data;
            this.verControl = !this.verControl;
            this.controlImgCodeDialog = false;
          }else{
            this.$message({
              message: res.msg,
              type: 'warning',
            });
            this.controlImgCodeDialog = false;
          }
        })
      }else{
        this.$message({
          message: '请输入图片验证码结果!',
          type: 'warning'
        });
      }
    },
    // 提交手机号验证码
    submit(form){
      if(form.phone && form.code){
        let data = {
          IsQuickRregister:true,
          Mobile:form.phone,
          Code:form.code,
          Token:this.guid,
          LoginType:1,
          AuthMode:1,
        };
        quickRegister(data).then(res=>{
          console.log(res);
          if(res.ok == 1){
            this.$message({
              message: res.msg,
              type: 'warning',
            });
            // const options = JSON.stringify(res.data);
            window.open('https://h5.rlc534.com/sign-up/real');
            this.form.phone = '';
            this.form.code = '';
          }else{
            this.$message({
              message: res.msg,
              type: 'warning'
            });
          }
        })
      }else{
        this.$message({
          message: '请输入手机号和验证码！',
          type: 'warning',
        });
        this.handleShake();
      }
    },
    // 读秒
    countdowm() {
      this.refreshData = window.setInterval(() => {
        this.verSecond--;
        if (this.verSecond < 0) {
          //当倒计时小于0时清除定时器
          window.clearInterval(this.refreshData); //清除定时器
          this.verSecond = 60;
          this.verControl = true;
        }
      }, 1000);
    },
    // 正则判断电话号码格式是否正确
    isPhone(TEL) {
      let strTemp = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
      if (strTemp.test(TEL)) {
        return true;
      }
      return false;
    },
    // 控制抖动
    handleShake() {
      this.toShake= true
      // demo for next animation.
      setTimeout(() => {
        this.toShake= false
      }, 1000)
    },
    // 跳客服
    live800(){
      // window.open('https://chat32.live800.com/live800/chatClient/chatbox.jsp?companyID=215184&configID=35519&jid=5377035377')
      window.open(this.$parent.live800Url)
    },
    goLink(attr){
      if(attr == 0){
        window.open('https://h5.rlc538.com/sign-up/real');
      }else{
        var u = navigator.userAgent;
        if (/(iPhone|iPad|iPod|iOS)/i.test(u)) { //苹果
          try {
            window.webkit.messageHandlers.gotoRegisterReal.postMessage('1');
          } catch (error) {
            window.open('https://h5.rlc538.com/sign-up/real');
          }
        } else { // 安卓
          try {
            window.android.gotoRegisterReal();
          } catch (error) {
            window.open('https://h5.rlc538.com/sign-up/real');
          }
        }
      }
    },
    // 返回上级页面
    goback(){
      window.location.href = window.location.origin;
    },
    // 控制顶部的定位
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if(this.scroll > 90 ){
        this.navBarFixed = true;
      }else{
        this.navBarFixed = false;
      }
    },
  },
  mounted(){
    window.addEventListener('scroll', this.handleScroll);
  },
  watch:{
    'form.phone'(newval){
      // console.log(newval);
    },
    // 立即执行
    immediate: true,
  }
}
</script>

<style lang="less" scoped>
.bigBox{
  height: auto;
  .tab{
    width: 7.5rem;
    height: 50px;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding-left: 10px;
    img{
      width: 120px;
    }
  }
  .navBarFixed{
    position: fixed;
    top: 0;
    z-index: 999;
  }
  .box{
    width: 7.5rem;
    height: 27.72rem;
    background: url('/activity/voucher/background.jpg') no-repeat;
    background-size: 100% 100%;
    position: relative;
    #login{
      width: 6.86rem;
      height: 3.61rem;
      background: url('/activity/voucher/loginBanner.png') no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%,0);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p{
        font-size: 0.36rem;
        font-weight: 600;
        color: #ff8038;
        margin: 0rem auto 0.3rem;
      }
      img{
        width: 4.18rem;
        margin-bottom: 0.3rem;
      }
      .interactive{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        input{
          width: 80%;
          height: 0.78rem;
          background: #fff;
          border: 1.5px solid #ff8038;
          border-radius: 14px;
          font-size: 0.2rem;
          margin-bottom: 0.28rem;
          text-indent: 0.3rem;
        }
        .codeBox{
          width: 100%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          span{
            width: 1.9rem;
            height: 0.83rem;
            line-height: 0.83rem;
            font-size: 0.18rem;
            border-radius: 14px;
            background-color: #ff8038;
            text-align: center;
            color: #fff;
            position: absolute;
            top: 1px;
            right: 32px;
          }
        }
        button{
          width: 72%;
          height: 0.8rem;
          background-color: #ff8038;
          border: 1px solid #ff8038;
          border-radius: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 0.3rem;
        }
      }
      .dollar{
        font-size: 0.30rem;
        color: #ff8038;
        margin-top: 10px;
      }
      .codeDialog{
        width: 95%;
        height: 4.5rem;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: #fef6e9;
        border-radius: 15px;
        z-index: 99;
        border: 1px solid #ffd8a6;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        img{
          width: 3rem;
          margin-bottom: 0;
        }
        p{
          span{
            color: #7f78fa;
            font-size: 0.32rem;
            font-weight: 600;
          }
          input{
            width: 2.5rem;
            height: 0.8rem;
            background-color: #ffffff;
            border: none;
            margin-left: 0.2rem;
            text-indent: 0.2rem;
            color: #7f78fa;
          }
        }
        div{
          display: flex;
          justify-content: center;
          align-items: center;
          button{
            width: 2rem;
            height: 0.8rem;
            border: 1px solid #ff8038;
            background-color: #ffffff;
            color: #ff8038;
            font-size: 0.3rem;
            border-radius: 8px;
            margin: 0 10px;
          }
          button:nth-child(2){
            background-color: #ff8038;
            color: #ffffff;
          }
        }
      }
    }
    .shaking {
      animation-delay: 0s;
	    animation-name: shake;
	    animation-duration: 0.1s;
	    animation-iteration-count: 3;
	    animation-direction: normal;
	    animation-timing-function: linear;
    }
    @keyframes shake {
	    0% {
        margin-left: 0px;
        margin-right: 3px;
        margin-top: 0px;
	    }
	    100% {
        margin-left: 3px;
        margin-right: 0px;
        margin-top: 3px	;
	    }
	  }
    .rulesP{
      position: absolute;
      top: 48.5%;
      left: 50%;
      transform: translate(-50%,-50%);
      font-size: 0.35rem;
      font-weight: 600;
      z-index: 2;
      color: #3f258f;
    }
    .part1{
      width: 6.86rem;
      position: absolute;
      top: 44.2%;
      left: 50%;
      transform: translate(-50%,0);
    }
    .part2{
      width: 6.86rem;
      position: absolute;
      top: 72.3%;
      left: 50%;
      transform: translate(-50%,0);
    }
    .part3{
      width: 6.86rem;
      position: absolute;
      top: 85.5%;
      left: 50%;
      transform: translate(-50%,0);
    }
    .part4{
      width: 6.86rem;
      position: absolute;
      top: 93.5%;
      left: 50%;
      transform: translate(-50%,0);
    }
  }
  .mask {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    z-index: 3;
  }
  .popup {
    width: 95%;
    height: 11.5rem;
    border-radius: 0.1rem;
    background: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%;-50%);
    box-sizing: border-box;
    padding: 10px;
    box-shadow: 0px 0px 95px 0px rgba(43, 53, 105, 0.1);
    overflow-y: scroll;
    .closeBox {
      height: 0.5rem;
      .guanbi1 {
        float: right;
        width: 0.35rem;
        cursor: pointer;
        margin-right: 0.1rem;
      }
    }
    h3{
      font-size: 0.32rem;
      margin: -0.3rem auto 0rem;
      text-align: center;
      color: #6a32aa;
    }
    .dialogContent {
      box-sizing: border-box;
      padding: 0.2rem 0.1rem;
      li {
        font-size: 0.24rem;
        color: rgb(31, 30, 30);
        line-height: 0.5rem;
        span {
          font-weight: 600;
          font-size: 0.28rem;
          color: #6a32aa;
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-track {
    background-color: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: none;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: none;
  }

  ::-webkit-scrollbar-thumb:active {
    background-color: none;
  }
}
</style>